import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../layout/layout';
import BlogSlider from '../components/BlogSlider';
import BlogDetails from '../components/BlogDetails';
import SEO from '../components/SEO';

const bloglayout = ({ data }) => {
  const {
    cover,
    title,
    keywords,
    technology,
    date,
    metaDescription,
    metaKeywords,
  } = data.markdownRemark.frontmatter;
  const post = data.markdownRemark;
  const keywordsArray =
    keywords?.split('/').map((keyword) => keyword.trim().toLowerCase()) || [];
  const technologyArray =
    technology?.split(',').map((tech) => tech.trim().toLowerCase()) || [];

  // Filter out posts that share at least one keyword or technology with the current post
  const relatedPosts = data.allMarkdownRemark.nodes
    .filter((node) => {
      const nodeKeywords = node.frontmatter.keywords
        ?.split('/')
        .map((keyword) => keyword.trim().toLowerCase());
      const nodeTechnology = node.frontmatter.technology
        ?.split(',')
        .map((tech) => tech.trim().toLowerCase());
      return (
        keywordsArray.some((keyword) => nodeKeywords.includes(keyword)) ||
        technologyArray.some((tech) => nodeTechnology.includes(tech))
      );
    })
    .slice(0, 3); // Limit to 3 related posts

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      // Function to scroll to anchor
      const scrollToAnchor = (href) => {
        const anchor = document.querySelector(href);
        if (anchor) {
          window.scrollTo({
            top: anchor.offsetTop - 40,
            behavior: 'smooth',
          });
          // Update URL
          window.history.pushState(null, null, href);
        }
      };

      // Scroll to anchor on click
      const links = document.querySelectorAll('a') || [];
      links &&
        links.forEach((link) => {
          link.addEventListener('click', (event) => {
            const href = link.getAttribute('href');
            if (href.startsWith('#')) {
              event.preventDefault();
              scrollToAnchor(href);
            }
          });
        });

      // Scroll to anchor on page load
      if (window.location.hash) {
        setTimeout(() => {
          scrollToAnchor(window.location.hash);
        }, 0);
      }

      // Clean up function to remove the event listener
      return () => {
        links &&
          links.forEach((link) => {
            link.removeEventListener('click');
          });
      };
    }
  }, []); // Run once on component mount

  return (
    <Layout pageTitle={title}>
      <SEO
        title={title}
        description={metaDescription ?? post.excerpt}
        metaKeywords={metaKeywords}
      />
      <BlogDetails img={cover} title={title} tag={keywords} postedOn={date}>
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
      </BlogDetails>
      {relatedPosts.length ? (
        <BlogSlider
          error="No similar articles found"
          title="Similar Articles"
          posts={relatedPosts}
        />
      ) : (
        ''
      )}
    </Layout>
  );
};

export default bloglayout;

export const query = graphql`
  query ($slug: String) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        date
        title
        cover
        showCaseImage
        keywords
        technology
        metaDescription
        metaKeywords
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog-post/.*.md$/" }
        fields: { slug: { ne: $slug } }
      }
    ) {
      nodes {
        frontmatter {
          cover
          title
          keywords
          technology
          date
        }
        fields {
          slug
        }
        excerpt
      }
    }
  }
`;
